header
  background: linear-gradient(90deg, rgba(201, 137, 221, 0.9) -8.54%, rgba(133, 202, 250, 0.9) 108.12%)
  padding: 15px 30px
  ul
    justify-content: space-between
    display: flex
    .inline-top
      display: flex
    .inline
      display: flex
      justify-content: center
      align-items: center
    .dropdown
      font-family: "AvenirNextLTPro-Regular", serif
      color: #FFFFFF
      font-size: 14px
      font-weight: 600
      line-height: 17px
      cursor: pointer

    .logoHeader
      font-family: "AvenirNextLTPro-Regular", serif
      color: #FFFFFF
      font-size: 32px
      font-weight: 600
      line-height: 39px
      cursor: pointer

.menu
  display: flex
  padding: 15px 30px
  li
    a
      margin-right: 10px
      font-family: "AvenirNextLTPro-Regular", serif
      font-size: 14px
      font-weight: 400
      line-height: 17px
      color: #303030

@media (max-width: 767px)
  header
    ul
      flex-direction: column
      align-items: center
      justify-content: center
    .roundCircle.smallSize
      width: 17px
    .roundCircle
      width: 25px
      height: 25px
      svg
        width: 16px
        height: 16px