.home
  button
    outline: none
    box-shadow: 0 0 14px 0 #C989DD80
    background: #C989DD
    padding: 5px 23px
    border-radius: 5px
    font-family: "AvenirNextLTPro-Regular", serif
    color: #FFFFFF
    font-size: 14px
    font-weight: 600
    line-height: 17px
    border: none
    cursor: pointer


  .headerHome
    display: flex
    background: white
    padding: 15px 30px 15px 30px
    justify-content: space-between
    align-items: center
    .topMenu
      display: flex
      align-items: center
      li
        padding: 10px 10px 10px 0
        cursor: pointer
        &.divider
          padding-right: 20px
          padding-left: 50px
          cursor: default
        &.active
          background: #6DC0F91A
          color: #6DC0F9
          border-radius: 20px
          padding: 5px 20px


  .tableMenuHome
    background: #AAAAAA12
    margin: 20px auto
    max-width: 592px
    padding: 7px
    display: flex
    align-items: center
    position: relative
    .searchBox
      position: relative
    .filterSelected
        cursor: pointer
        background: #6DC0F91A
        color: #6DC0F9
        border-radius: 20px
        padding: 5px 20px
    .filterDeleted
      cursor: pointer
      padding: 5px 20px
      color: #F48888

    svg
      position: absolute
      z-index: 2
      right: 15px
      top: 12px
      border-radius: 10px
    input
      outline: none
      border: 2px solid #AAAAAA33
      padding: 10px 24px
      box-sizing: border-box
      border-radius: 8px


  .tableHome
    margin: 20px 0 50px 0
    overflow: auto
    .table
      min-width: 850px
      display: grid
      grid-template-columns: 1.2fr repeat(4, 1fr) 0.5fr 0.5fr
      .th:not(.first), .td:not(.first)
        text-align: center
      > .td.center
        text-align: center
      > .th
        cursor: pointer
        padding: 20px 15px
        color: #303030
        border-bottom: 1px solid #AAAAAA33
        border-top: 1px solid #AAAAAA33
      > .th.faded
        color: #AAAAAA
      > .th.first
        padding-left: 35px
      > .th.last
        padding-right: 35px
      .header
        padding: 10px 4px
        font-family: Montserrat, serif
        font-size: 16px
        font-weight: 600
        line-height: 20px
        margin-top: 40px
      .td.first
          padding-left: 5px
          margin-left: 30px
      .td.last
          margin-right: 30px
          padding-right: 5px
    .th, .td
      color: #303030
      padding: 20px 4px
      border-bottom: 1px solid black
      input
        position: relative
        top: 2px
        margin-right: 10px


  .adHome
    display: flex
    justify-content: space-between
    align-items: center
    padding: 40px 30px 30px 30px
    background: white
    h2
      font-family: Montserrat, serif
      font-size: 32px
      font-weight: 700
      line-height: 39px
      color: #303030

    p
      margin: 10px 0 0 0
      font-family: "AvenirNextLTPro-Regular", serif
      font-size: 16px
      font-weight: 400
      line-height: 19px
      color: #AAAAAA


  .mainLinks
    display: flex
    font-family: "AvenirNextLTPro-Regular", serif
    font-size: 14px
    font-weight: 400
    line-height: 22px
    .firstColumn
      flex-basis: 25%
    .secondColumn
      flex-basis: 50%
    .thirdColumn
      flex-basis: 25%
    h3
      font-family: Montserrat, serif
      font-size: 18px
      font-weight: 700
      line-height: 22px
      margin: 0 0 20px 0
      color: #6DC0F9
    ul
      padding-right: 30px
    .wrapMenu
      display: flex
      flex-direction: column
      flex-wrap: wrap
      max-height: 270px


  .footerLinksHome
    padding: 15px 30px 50px 30px
    .alphabetLinks
      .linkHeaderSmall
        font-family: Montserrat, serif
        font-size: 18px
        font-weight: 700
        line-height: 22px
        color: #6DC0F9
        margin: 40px 0 10px 0
    .simpleHeaderText
      font-family: "AvenirNextLTPro-Regular", serif
      font-size: 14px
      font-weight: 400
      line-height: 17px
      margin-right: 50px
    .letters
      font-family: "AvenirNextLTPro-Regular", serif
      font-size: 14px
      font-weight: 600
      line-height: 17px
      letter-spacing: 4px
      cursor: pointer

@media (max-width: 850px)
  .home .mainLinks
    flex-wrap: wrap
    .thirdColumn
      flex-basis: 50%
      order: 1
    .secondColumn
      flex-basis: 100%
      order: 3
    .firstColumn
      flex-basis: 50%
      order: 2
  .home .headerHome,
  .home .headerHome .topMenu,
  .home .headerHome .topMenu,
  .home .tableMenuHome,
  .home .adHome
    flex-wrap: wrap
    .divider
      display: none
    button
      margin-top: 10px
  .home .mainLinks h3
    margin-top: 20px
  .home .headerHome .topMenu li
    padding: 5px 20px
  .home .tableMenuHome > span
    flex-basis: 50%
    margin-bottom: 20px
    box-sizing: border-box
  .home .tableMenuHome > div
    flex-basis: 100%
    margin-bottom: 20px
    input
      width: 100%
  .home .letters
    display: block
    margin-top: 20px
    letter-spacing: 1px