.dashboard
  display: flex
  min-height: calc(100vh - 196px)
  .left
    padding: 15px 10px 40px 30px
    border-right: 1px solid #AAAAAA
    flex-basis: 360px
    box-sizing: border-box
    display: flex
    flex-direction: column
    justify-content: space-between

    .headerSection
      display: flex
      justify-content: space-between
      margin-bottom: 20px
      h2
        font-family: Montserrat, serif
        font-size: 20px
        font-weight: 700
        line-height: 24px
      button
        cursor: pointer
        padding: 6px 10px
        border-radius: 3px
        border: 0.5px solid #AAAAAA70
        background: #AAAAAA10
        font-family: "AvenirNextLTPro-Regular", serif
        font-size: 12px
        font-weight: 600
        line-height: 14px
        color: #AAAAAA


    .taskSection
      .card
        position: relative
        border-radius: 10px
        margin: 10px 25px 25px 0
        &.yellow
          border: 2px solid #FCB769
          background: #FCB769
          button
            background: #C989DD

        &.purple
          border: 2px solid #C989DD
          background: #C989DD
          button
            background: #FCB769

        .cardContent
          padding: 10px
          background: white
          border-radius: 0 0 10px 10px
          h4
            margin: 0
            font-family: Montserrat, serif
            font-size: 14px
            font-weight: 600
            line-height: 17px
            color: #303030
          .subHeader
            font-family: "AvenirNextLTPro-Regular", serif
            font-size: 12px
            font-weight: 600
            line-height: 14px
            color: #AAAAAA
          p
            font-family: "AvenirNextLTPro-Regular", serif
            font-size: 12px
            font-weight: 400
            line-height: 14px
            color: #303030
          .buttonWrapper
            display: flex
            justify-content: flex-end
          button
            font-family: "AvenirNextLTPro-Regular", serif
            font-size: 12px
            font-weight: 600
            line-height: 14px
            color: #FFFFFF
            padding: 4px 10px 4px 10px
            border-radius: 4px
            outline: none
            border: none
            cursor: pointer

    .headCard
      display: flex
      font-family: "AvenirNextLTPro-Bold", serif
      align-items: center
      padding: 15px 10px
      box-sizing: border-box
      color: #FFFFFF
      font-size: 14px
      font-weight: 700
      line-height: 17px
      position: relative
      .timer
        display: flex
        align-items: center
        margin-left: 25px
      .marker
        position: absolute
        right: 10px
        top: 10px


  .footerSection
    display: flex
    align-items: center
    .redBadge
      cursor: pointer
      border-radius: 20px
      padding: 5px 15px
      font-family: "AvenirNextLTPro-Regular", serif
      font-size: 12px
      font-weight: 400
      background: #F488881A
      color: #F48888
      line-height: 16px
      margin-right: 20px
    .greyBadge
      cursor: pointer
      border-radius: 20px
      padding: 5px 15px
      font-family: "AvenirNextLTPro-Regular", serif
      font-size: 12px
      font-weight: 400
      background: #AAAAAA33
      color: #AAAAAA
      line-height: 16px


  .right
    flex-basis: calc(100vw - 440px)
    padding: 0 40px
    .infoSection
      display: flex
      align-items: center
    .rightHeader
      display: flex
      align-items: center
      justify-content: space-between
      h2
        font-family: Montserrat, serif
        font-size: 20px
        font-weight: 700
        line-height: 24px
      button
        background: linear-gradient(180deg, #6DC0F9 0%, #42B2FF 100%)
        padding: 13px 23px 13px 23px
        border-radius: 50px
        font-family: "AvenirNextLTPro-Regular", serif
        font-size: 14px
        font-weight: 600
        line-height: 17px
        color: #FFFFFF
        outline: none
        border: none
        cursor: pointer
        margin: 0 0 0 20px
      .greyBadge
        cursor: pointer
        border-radius: 20px
        padding: 5px 15px
        font-family: "AvenirNextLTPro-Regular", serif
        font-size: 12px
        font-weight: 400
        background: #AAAAAA33
        color: #AAAAAA
        margin-left: 10px
        line-height: 16px
        &.active
          background: #9E58FF33
          color: #9E58FF


  .rightTable
    margin: 30px 0 50px 0
    position: relative
    .table
      display: grid
      grid-template-columns: 0.5fr repeat(7, 1fr)
      grid-template-rows: 90px repeat(15, 50px)
    .table > .th, .table > .td
      padding: 8px 4px
      border-left: 1px solid #E9E9E9
      text-align: center
      border-bottom: 1px solid #E9E9E9
      cursor: pointer
      font-family: "AvenirNextLTPro-Regular", serif
      font-size: 12px
      font-weight: 400
      line-height: 14px
      position: relative

    .table > .td span.schedule
      position: relative
      bottom: 20px
      right: 5px
    .table > .first
      border: none
      border-right: 1px solid #E9E9E9
    .table > .th:not(.first)
      border-top: 1px solid #E9E9E9
    .table > .th.faded
      opacity: .5
    .table .arrowWrapper
      position: relative
      top: 20px
      left: 15px
      border: 1px solid #E9E9E9
      width: 21px
      text-align: center
      border-radius: 100%
    .rightArrow
      border: 1px solid #E9E9E9
      width: 21px
      text-align: center
      border-radius: 100%
      position: absolute
      left: 100%
      top: 28px
      transform: scale(-1, 1)
    .booked
      position: absolute
      top: -50%
      left: 0
      background: #FCB769
      width: 100%
      height: 100%
      border-radius: 8px
      padding: 10px
      box-sizing: border-box
      &:after
        content: ''
        display: block
        background: #9E58FF
        position: absolute
        right: 0
        top: 0
        width: 17px
        height: 100%
        z-index: 1
        border-radius: 0 8px 8px 0
      .title
        font-family: "AvenirNextLTPro-Regular", serif
        font-size: 12px
        font-weight: 600
        line-height: 12px
        display: block
        color: #FFFFFF
        text-align: left
      .time
        display: block
        font-family: "AvenirNextLTPro-Regular", serif
        font-size: 12px
        font-weight: 400
        line-height: 12px
        color: #FFFFFF
        text-align: left

    .badgeRight
      position: absolute
      transform: rotate(90deg)
      top: 18px
      right: -4px
      z-index: 2
      color: #FFFFFF
      font-family: "AvenirNextLTPro-Regular", serif
      font-size: 8px
      font-weight: 600
      line-height: 10px

@media (max-width: 850px)
  .dashboard
    flex-wrap: wrap
    .right, .left
      flex-basis: 100%

@media (max-width: 600px)
  .right
    overflow: auto
    .rightTable
      min-width: 500px


@media (max-width: 1200px)
  .dashboard .rightTable .booked
    padding: 5px 15px 5px 5px
    .title,
    .time
      font-size: 10px