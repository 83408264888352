.roundCircle
  width: 35px
  height: 35px
  margin: 0 10px
  padding: 2px
  cursor: pointer
  box-sizing: border-box
  display: flex
  align-items: center
  justify-content: center
  border-radius: 100%
  background: #9E58FF
  position: relative
  &.whiteBg
    background: #FFFFFF
  &.transparentBg
    background: transparent
  &.smallSize
    width: 30px
    height: 30px
  .upper
    position: absolute
    top: 0
    right: 0
    background: #E85555
    width: 11px
    height: 11px
    border-radius: 100%
