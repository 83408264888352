footer
  display: flex
  justify-content: space-between
  padding: 15px 30px
  align-items: center
  background: white
  .social
    display: flex
  .copyright
    font-family: "AvenirNextLTPro-Regular", serif
    font-size: 12px
    font-weight: 400
    line-height: 14px
    color: #303030
  .footerLogo
    color: #9E58FF
    font-family: "AvenirNextLTPro-Regular", serif
    font-size: 27px
    font-weight: 600
    line-height: 33px