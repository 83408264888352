@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap')

@font-face
  font-family: "AvenirNextLTPro-Bold"
  src: local("AvenirNextLTPro-Bold"), url("../fonts/AvenirNextLTPro-Bold.otf") format("opentype")

@font-face
  font-family: "AvenirNextLTPro-BoldCn"
  src: local("AvenirNextLTPro-BoldCn"), url("../fonts/AvenirNextLTPro-BoldCn.otf") format("opentype")

@font-face
  font-family: "AvenirNextLTPro-Demi"
  src: local("AvenirNextLTPro-Demi"), url("../fonts/AvenirNextLTPro-Demi.otf") format("opentype")

@font-face
  font-family: "AvenirNextLTPro-DemiCn"
  src: local("AvenirNextLTPro-DemiCn"), url("../fonts/AvenirNextLTPro-DemiCn.otf") format("opentype")

@font-face
  font-family: "AvenirNextLTPro-It"
  src: local("AvenirNextLTPro-It"), url("../fonts/AvenirNextLTPro-It.otf") format("opentype")

@font-face
  font-family: "AvenirNextLTPro-Regular"
  src: local("AvenirNextLTPro-Regular"), url("../fonts/AvenirNextLTPro-Regular.otf") format("opentype")

body, ul, h2
  margin: 0
  padding: 0
  list-style: none
  font-family: "AvenirNextLTPro-Regular", serif

body
  background: #F8F8F8
  user-select: none
  overflow-x: hidden

main
  min-height: calc(100vh - 196px)

svg
  cursor: pointer